import { useEffect, useState } from 'react';
import {db, functions} from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { collection, onSnapshot, query, orderBy, limit } from 'firebase/firestore';
import moment from 'moment-timezone';
import sizeof from 'firestore-size';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from "@mui/material/Modal";
import Typography from '@mui/material/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import OrderCreateModal from './OrderCreateModal.js';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Unstable_Grid2';
import SalesStats from './SalesStats.js';
import RegionTabs from './RegionTabs.js';

const ProdOrder = ({ setTitle, setLoading, setMenuItems }) => {
    const [salesData, setSalesData] = useState();
    const [inventoryData, setInventoryData] = useState();
    const [requiredQties, setRequiredQties] = useState();
    const [expectedSales, setExpectedSales] = useState();
    const [baseInventory, setBaseInventory] = useState();
    const [orderQties, setOrderQties] = useState();
    const [isStoreOrderable, setIsStoreOrderable] = useState();
    const [isStyleOrderable, setIsStyleOrderable] = useState();
    const [restockingInterval, setRestockingInterval] = useState(3); // Nb months between the orders
    const [restockingIntervalDates, setRestockingIntervalDates] = useState({}); // The dates last year of the next restocking interval
    const [restockingSales, setRestockingSales] = useState(); // The sales per product last year between the next restocking inverval dates
    const [restockingRecentSales, setRestockingRecentSales] = useState() // The sales per product in the most recent past of the restocking interval length
    const [productionTime, setProductionTime] = useState(2); // Nb months from order to delivery
    const [productionDates, setProductionDates] = useState({}); // The dates last year of the next production time
    const [productionSales, setProductionSales] = useState(); // The sales per product last year during the next the production time
    const [productionRecentSales, setProductionRecentSales] = useState(); // the sales per product in the most recent past of the production time length
    const [customExpectedSales, setCustomExpectedSales] = useState({});
    const [barcodes, setBarcodes] = useState();
    const [orderAnchorEl, setOrderAnchorEl] = useState(null);
    const [modalContent, setModalContent] = useState();

    const minQtyPerStore = 70;
    const minQtyPerStyle = 16;

    moment.tz.setDefault("America/Toronto");

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        maxHeight: '75vh',
        overflow: 'auto',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    useEffect(() => {
        const menuItems = <>
            <IconButton
                aria-label="download"
                aria-controls={orderMenuId}
                aria-haspopup="true"
                onClick={handleOrderMenuOpen}
                color="inherit"
            >
                <MoreHorizIcon />
            </IconButton>
        </>
        setMenuItems(menuItems);
    }, [setMenuItems])

    const handleModalOpen = (modalContent) => setModalContent(modalContent);
    const handleModalClose = () => setModalContent(undefined);

    /**
     * Get inventory data
     */
    useEffect(() => {
        setTitle('FABRIQUE');
        setLoading(true);
        const q = query(collection(db, 'stats-inventory'), orderBy('createdAt', 'desc'), limit(1));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const docs = querySnapshot.docs
            if (querySnapshot.empty || docs[0].data().createdAt < Date.now() - 24 * 60 * 60 * 1000) {
                // View doesn't exist or is more than 24 hours old
                console.log("Requesting inventory update")
                const requestUpdate = httpsCallable(functions, 'fabrique-getInventory');
                requestUpdate();
            } else {
                const data = querySnapshot.docs[0].data();
                console.log(`stats-inventory size: ${sizeof(data)}B (${(sizeof(data)/(1024*1024)*100).toFixed(1)}%)`);
                setInventoryData(data);
            }
            setLoading(false);
        });
        return unsubscribe;
    }, []);

    /**
     * Upon receiving the inventoryData and salesData the required quantity of each SKU per store
     */
    useEffect(() => {
        if (!inventoryData || !salesData) return;
        console.debug('Calculating required quantity of each SKU...');
        const nextIsStoreOrderable = {};
        const nextRequiredQties = {};
        const nextExpectedSales = {};
        const nextProductionSales = {};
        const nextProductionRecentSales = {};
        const nextProductionDates = {
            start: moment(salesData.createdAt).startOf("day").valueOf(),
            end: moment(salesData.createdAt).add(productionTime, "months").subtract(1, "day").startOf("day").valueOf(),
        }
        const nextBaseInventory = {};
        const nextRestockingSales = {};
        const nextRestockingRecentSales = {};
        const nextRestockingIntervalDates = {
            start: moment(salesData.createdAt).add(productionTime, "months").startOf("day").valueOf(),
            end: moment(salesData.createdAt).add(productionTime + restockingInterval, "months").subtract(1, "day").startOf("day").valueOf(),
        }
        const nextBarcodes = {};
        const growthRate = salesData.lastYear.compareAt === 0 ? 0 : (salesData.lastYear.total / salesData.lastYear.compareAt); // if no lastYear data, division by 0 will be 'NaN' or 'Infinity'

        for (const regionCode of Object.keys(inventoryData.region)) {
            // For each variant
            for (const {sku, size, barcode, productId, available=0, incoming=0} of inventoryData.region[regionCode]) {
                nextBarcodes[sku] = barcode;
                const ratioOfSize = (salesData.allTimeBySize.find(item => item.size === size)?.qty / salesData.allTime) || 0;

                /* 
                    Calculate the required qty
                    baseInventory: The expected inventory required to go through the following x months once we receive the stock.
                        Calculated as: sales from (currentDate + threshold - 1year) to (currentDate + threshold + threshold * buffer) * pastYearGrowthRate
                        --> the buffer is min 1 and can be adjusted to have the base inventory cover for a longer period than the threshold (security margin)
                    expectedSales: The expected sales in the next x months to cover for.
                        Calculated as: sales from (currentDate - 1year) to (currentDate + threshold -1year) * pastYearGrowthRate
                */

                // Expected sales
                
                // Sales for the past periods for the product x ratio of size x regional increase in sales from last year
                let prodLastYearSales = 0;
                for (let i = 1; i <= productionTime; i++) { // Each period
                    // TODO: Include sales from aliases
                    prodLastYearSales += (salesData.lastYearByProductByRegionByPeriod?.[regionCode]?.[productId]?.[i] || 0); // Total sales for that period
                }
                if (!nextProductionSales[sku]) {
                    nextProductionSales[sku] = {};
                }
                nextProductionSales[sku][regionCode] = prodLastYearSales;

                // Fallback for salesThresholdPast if the sales of last year are too low (ex: new product)
                let prodRecentSales = 0;
                // Calculate the sales of the past {productionTime} months and maybe use that number instead
                for (let i = 13 - productionTime; i <=12 ; i++) { // Each period
                    prodRecentSales += (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[productId]?.[i] || 0); // Total sales for that period
                }
                if (!nextProductionRecentSales[sku]) {
                    nextProductionRecentSales[sku] = {}
                }
                nextProductionRecentSales[sku][regionCode] = prodRecentSales;

                let projectedProductionSales = prodLastYearSales * growthRate;

                if (prodRecentSales > projectedProductionSales && prodLastYearSales < productionTime * 5) { // We prefer last year data for the accurate months if there are enough sales
                    projectedProductionSales = prodRecentSales;
                }

                if (!nextExpectedSales[sku]) {
                    nextExpectedSales[sku] = {};
                }
                nextExpectedSales[sku][regionCode] = (parseInt(customExpectedSales[regionCode]?.[sku]) || undefined) ?? Math.round(projectedProductionSales * ratioOfSize);

                // Base inventory

                if (!nextBaseInventory[sku]) {
                    nextBaseInventory[sku] = {};
                }
            
                if (productionTime + restockingInterval > 12) {
                    throw new Error(`The data required to calculate the baseInventory doesn't exist. It requires to analyse past sales that are in the future. Reduce the forcastThreshold or the inventory security buffer.`);
                }
                let salesBasePast = 0;
                for (let i = 1 + productionTime; i <= productionTime + restockingInterval; i++) { // Each period of the 
                    salesBasePast += (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[productId]?.[i] || 0); // Total sales for that period
                }
                if (!nextRestockingSales[sku]) {
                    nextRestockingSales[sku] = {};
                }
                nextRestockingSales[sku][regionCode] = salesBasePast;

                // Fallback for salesBasePast if the sales of last year are too low (ex: new product)
                let baseRecentSales = 0;
                // Calculate the sales of the past {productionTime} months and maybe use that number instead
                for (let i = 13 - restockingInterval; i <=12 ; i++) { // Each period
                    baseRecentSales += (salesData.lastYearByProductByRegionByPeriod[regionCode]?.[productId]?.[i] || 0); // Total sales for that period
                }
                if (!nextRestockingRecentSales[sku]) {
                    nextRestockingRecentSales[sku] = {};
                }
                nextRestockingRecentSales[sku][regionCode]  = baseRecentSales;
                let projectedRestockingSales = salesBasePast * growthRate;

                if (baseRecentSales > projectedRestockingSales && salesBasePast < restockingInterval * 5) {
                    projectedRestockingSales = baseRecentSales;
                }
                nextBaseInventory[sku][regionCode] = Math.max(Math.round(projectedRestockingSales * ratioOfSize), 2);
                
                // Calculate required inventory to order
                const required = Math.max(Math.ceil((nextBaseInventory[sku][regionCode] + nextExpectedSales[sku][regionCode] - (available + incoming))/2)*2, 0);

                if (nextRequiredQties[sku] === undefined) {
                    nextRequiredQties[sku] = {};
                }
                nextRequiredQties[sku][regionCode] = required;
            }
        }
        setBarcodes(nextBarcodes);
        setRequiredQties(nextRequiredQties);
        setExpectedSales(nextExpectedSales);
        setProductionSales(nextProductionSales);
        setProductionRecentSales(nextProductionRecentSales);
        setProductionDates(nextProductionDates);
        setBaseInventory(nextBaseInventory);
        setRestockingSales(nextRestockingSales);
        setRestockingRecentSales(nextRestockingRecentSales);
        setRestockingIntervalDates(nextRestockingIntervalDates);
        setIsStoreOrderable(nextIsStoreOrderable);
    }, [inventoryData, salesData, customExpectedSales, productionTime, restockingInterval]);

    /** Upon receiving new requiredQties, sum up the total qty per style to identify which styles have enough qty to start production */
    useEffect(() => {
        // TODO: Move All calculations related to requiredQty in the same block to prevent infinite loop.
        if (!requiredQties) return;
        console.debug(`Calculating orderableStores and orderableStyles...`);
        
        // Helper function to extract style from SKU
        const extractStyle = (sku) => sku.split('-').slice(0, -1).join('-');

        let orderableStores = {};
        let orderableStyles = {};
        let lastOrderableStores = {};
        let lastOrderableStyles = {};
        let iterationCount = 0;

        do {
            // Assume changes to re-evaluate conditions
            lastOrderableStores = { ...orderableStores };
            lastOrderableStyles = { ...orderableStyles };
    
            // Reset for recalculation
            let storeTotals = {};
            let styleTotals = {};
    
            // Calculate totals by store and by style
            Object.entries(requiredQties).forEach(([sku, quantities]) => {
                const style = extractStyle(sku);
    
                Object.entries(quantities).forEach(([store, quantity]) => {
                    if (iterationCount === 0 || lastOrderableStores[store]) {
                        storeTotals[store] = (storeTotals[store] || 0) + quantity;
                        styleTotals[style] = (styleTotals[style] || 0) + quantity;
                    }
                });
            });
    
            // Update orderable stores and styles based on calculated totals
            orderableStores = Object.fromEntries(
                Object.entries(storeTotals).map(([store, total]) => [store, total >= minQtyPerStore])
            );
    
            orderableStyles = Object.fromEntries(
                Object.entries(styleTotals).map(([style, total]) => [style, total >= minQtyPerStyle])
            );
    
            iterationCount++;
        } while (
            JSON.stringify(lastOrderableStores) !== JSON.stringify(orderableStores) ||
            JSON.stringify(lastOrderableStyles) !== JSON.stringify(orderableStyles)
        );

        /** Once we have the required quantities, the orderable styles and the orderable stores, calculate the order quantities */
        console.debug('Calculating order quantities...')
        const order = {}
        for (const sku in requiredQties) {
            if (!orderableStyles[extractStyle(sku)]) continue;
            order[sku] = {};
            for (const store in requiredQties[sku]) {
                order[sku][store] = orderableStores[store] ? requiredQties[sku][store] : 0;
            }
        }
        setOrderQties(order);
        setIsStoreOrderable(orderableStores);
        setIsStyleOrderable(orderableStyles);
    }, [requiredQties]);

    const handleOrderMenuClose = () => {
        setOrderAnchorEl(null);
    };

    const isOrderMenuOpen = Boolean(orderAnchorEl);

    const handleOrderMenuOpen = (event) => {
        setOrderAnchorEl(event.currentTarget);
    };

    const handleExpectedSalesChanged = ({store, sku, qty}) => {
        const newCustomExpectSales = {...customExpectedSales};
        if (!newCustomExpectSales[store]) {
            newCustomExpectSales[store] = {};
        }
        newCustomExpectSales[store][sku] = qty;
        setCustomExpectedSales(newCustomExpectSales);
    }

    const orderMenuId = 'account-menu';
    const renderOrderMenu = (
      <Menu
        anchorEl={orderAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={orderMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOrderMenuOpen}
        onClose={handleOrderMenuClose}
        disableScrollLock={true}
      >
        <MenuItem onClick={() => {
            handleModalOpen('create-order');
            handleOrderMenuClose();
        }}>Créer une commande</MenuItem>
        <MenuItem onClick={() => {
            handleModalOpen('create-order-reseller');
            handleOrderMenuClose();
        }}>Créer une commande revendeur</MenuItem>
      </Menu>
    );

    const getModalContent = () => {
        switch (modalContent) {
            case 'create-order':
            case 'create-order-reseller':
                let reseller = false;
                if (modalContent === 'create-order-reseller') {
                    reseller = true;
                }
                return <OrderCreateModal reseller={reseller} initialQties={orderQties} barcodes={barcodes} isStoreOrderable={isStoreOrderable} closeModal={handleModalClose} />
        }
        return null;
    }

    const handleSalesStatsUpdate = (data) => {
        console.debug("salesStats updated");
        console.debug({data});
        setSalesData(data);
    }

    const handleProdTimeChange = (evt) => {
        console.debug("productionTime changed");
        console.debug({productionTime: evt.target.value});
        setProductionTime(evt.target.value);
    }

    const handleRestockingIntervalChange = (evt) => {
        console.debug("restockingInterval changed");
        console.debug({restockingInterval: evt.target.value})
        setRestockingInterval(evt.target.value);
    }
      
    function valuetext(value) {
        return `${value}mo`;
    }
    
    return (
        <>
            <Stack direction="column" spacing={2} py={2}>
                <SalesStats onUpdate={handleSalesStatsUpdate} />
                <Divider />
                <Grid container spacing={2} maxWidth={800}>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            Production Time:
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Slider
                            aria-label="Production Time"
                            value={productionTime}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            shiftStep={1}
                            step={1}
                            marks
                            min={1}
                            max={3}
                            onChange={handleProdTimeChange}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            {productionTime} months - {restockingIntervalDates.start && moment(productionDates.start).format("MMM D")} to {moment(productionDates.end).format("MMM D, YYYY")}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            Restocking Interval:
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Slider
                            aria-label="Restocking Interval"
                            value={restockingInterval}
                            getAriaValueText={valuetext}
                            valueLabelDisplay="auto"
                            shiftStep={1}
                            step={1}
                            marks
                            min={1}
                            max={6}
                            onChange={handleRestockingIntervalChange}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <Typography id="input-slider" gutterBottom>
                            {restockingInterval} months - {restockingIntervalDates.start && moment(restockingIntervalDates.start).format("MMM D")} to {moment(restockingIntervalDates.end).format("MMM D, YYYY")}
                        </Typography>
                    </Grid>
                </Grid>
                <RegionTabs 
                    isStoreOrderable={isStoreOrderable}
                    isStyleOrderable={isStyleOrderable}
                    inventoryData={inventoryData}
                    baseInventory={baseInventory}
                    restockingInterval={restockingInterval}
                    restockingSales={restockingSales}
                    restockingRecentSales={restockingRecentSales}
                    restockingIntervalDates={restockingIntervalDates}
                    requiredQties={requiredQties}
                    expectedSales={expectedSales}
                    productionTime={productionTime}
                    productionSales={productionSales}
                    productionRecentSales={productionRecentSales}
                    productionDates={productionDates}
                    orderQties={orderQties}
                    onChangeExpectedSales={handleExpectedSalesChanged}
                />
            </Stack>
            <Modal open={!!modalContent} onClose={handleModalClose}>
                <Box sx={modalStyle}>
                    {getModalContent()}
                </Box>
            </Modal>
            {renderOrderMenu}
        </>
    )
}

export default ProdOrder;