import React from "react";
import usFlag from '../../usa-flag.svg';
import deFlag from '../../germany-flag.svg';
import euFlag from '../../europe-flag.svg';
import caFlag from '../../canada-flag.svg';

const Flag = ({flag, overlay, ...props}) => {
    if (!props.height && !props.width) {
        props.width = 35;
    }
    let src;
    switch (flag) {
        case 'USA':
            src = usFlag;
            break;
        case 'GERMANY':
            src = deFlag;
            break;
        case 'EUROPE':
            src = euFlag;
            break;
        case 'CANADA':
            src = caFlag;
            break;
    }
    
    return (
        <img {...props} src={src} alt={`${flag} flag`} />
    )
    
}

export default Flag;