import { useState, useEffect } from 'react';
import { TextField, alpha } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const StatsTable = ({tableData, productionTime, restockingInterval, onChangeExpectedSales}) => {
  const [total, setTotal] = useState(); 
  const largeScreen = useMediaQuery('(min-width:1100px)');
  const theme = useTheme();
  
  useEffect(() => {
    console.debug('Formatting tableData...')
    if (!tableData) return;
    const nextTotal = {
      target: 0,
      restockingSales: 0,
      restockingRecentSales: 0,
      available: 0,
      incoming: 0,
      expectedSales: 0,
      productionSales: 0,
      productionRecentSales: 0,
      expected: 0,
      required: 0,
      order: 0
    };
    // Helper function to extract style from SKU
    const extractStyle = (sku) => sku.split('-').slice(0, -1).join('-');
    const processedStyles = [];
    for (const item of tableData) {
        const style = extractStyle(item.sku);
        if (!processedStyles.includes(style)) {
          processedStyles.push(style);
          nextTotal.restockingSales += item.restockingSales;
          nextTotal.restockingRecentSales += item.restockingRecentSales;
          nextTotal.productionSales += item.productionSales;
          nextTotal.productionRecentSales += item.productionRecentSales;
        }
        nextTotal.target += item.target;
        nextTotal.available += item.available;
        nextTotal.incoming += item.incoming;
        nextTotal.expectedSales += item.expectedSales;
        nextTotal.expected += item.expected;
        nextTotal.required += item.required;
        nextTotal.order += item.order;
    }
    setTotal(nextTotal);
  }, [tableData]);

  return tableData && total ? (
      <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell title="Total sales per product last year during the same restocking interval" align="right">{largeScreen ? `Restocking Sales\n(${restockingInterval} mois)` : "RSpY"}</TableCell>
            <TableCell title="Total sales per product during the previous period of the restocking interval" align="right">{largeScreen ? `Restocking Sales (-${restockingInterval} mois)` : "RSpP"}</TableCell>
            <TableCell title="Required quantity to last through the next restocking interval" align="right">{largeScreen ? "Qté Cible" : "Cib"}</TableCell>
            <TableCell align="right">{largeScreen ? "Qté Actuelle" : "Act"}</TableCell>
            <TableCell align="right">{largeScreen ? "Qté Entrante" : "Ent"}</TableCell>
            <TableCell title="Total sales per product last year during the same production time" align="right">{largeScreen ? `Prod Sales (${productionTime} mois)` : "PSpY"}</TableCell>
            <TableCell title="Total sales per product during the previous period of production time" align="right">{largeScreen ? `Prod Sales (-${productionTime} mois)` : "PSpP"}</TableCell>
            <TableCell title="Required quantity per size to restock what will have been sold during the production period" align="right">{largeScreen ? `Ventes Prév.` : "Ven"}</TableCell>
            <TableCell title={`Expected qty when receiving the stock in ${productionTime} months`} align="right">{largeScreen ? `Qté Prév.` : "Pre"}</TableCell>
            <TableCell align="right">{largeScreen ? "Qté Requise" : "Req"}</TableCell>
            <TableCell align="right">{largeScreen ? "Commande" : "Cmd"}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell sx={{borderBottom: "none", top: 37}} align="left">Total</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.restockingSales}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.restockingRecentSales}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.target}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.available}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.incoming}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.productionSales}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.productionRecentSales}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.expectedSales}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.expected}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37}} align="right">{total.required}</TableCell>
              <TableCell sx={{borderBottom: "none", top: 37, fontWeight: "bold"}} align="right">{total.order}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.sort((a, b) => {
              if (a.sku < b.sku) {
                return -1;
              }
              if (a.sku > b.sku) {
                return 1;
              }
              return 0;
          }).map((row) =>{
            const rowColor = row.styleOrderable ? theme.palette.text.primary : theme.palette.grey[700];
            return (
            <TableRow
              key={row.sku}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, bgColor: (theme) => row.styleOrderable ? theme.palette.success : theme.palette.grey[700]}}
            >
              <TableCell align="left" sx={{color: rowColor}}>{row.sku}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.restockingSales}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.restockingRecentSales}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.target}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.available}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.incoming}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.productionSales}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.productionRecentSales}</TableCell>
              <TableCell align="right" sx={{color: rowColor, paddingRight: '6px', paddingLeft: "6px"}}>
              <TextField
                  id="filled-hidden-label-small"
                  defaultValue={row.expectedSales}
                  variant="filled"
                  size="small"
                  sx={{width:'54px'}}
                  inputProps={{inputMode: 'numeric', pattern: '[0-9]*', style: {textAlign: 'right', padding:"4px 10px 6px 10px", fontSize: "0.875rem"}}}
                  onBlur={(evt) => onChangeExpectedSales({sku: row.sku, qty:parseInt(evt.target.value) || 0})}
                  onKeyDown={(evt) => {
                    if (evt.key === "Enter") {
                      evt.preventDefault(); // Prevent the default action (if any)
                      // Get all focusable elements
                      const focusableElements = Array.from(document.querySelectorAll(
                        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
                      ));
                      // Get the currently focused element
                      const currentFocus = document.activeElement;
                      // Find the next focusable element
                      const currentIndex = focusableElements.indexOf(currentFocus);
                      const nextIndex = (currentIndex + 1) % focusableElements.length;
                      // Focus the next element
                      focusableElements[nextIndex].focus();
                    }
                  }}
                />
              </TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.expected}</TableCell>
              <TableCell align="right" sx={{color: rowColor}}>{row.required}</TableCell>
              <TableCell align="right" sx={{fontWeight: "bold", color: rowColor}}>{row.order}</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  ) : "Loading"
}

export default StatsTable;