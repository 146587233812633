import { useEffect, useState } from "react";
import {db, functions} from "../../firebase";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import { collection, query, onSnapshot, orderBy, limit } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import Grid from '@mui/material/Unstable_Grid2';
import StatCard from "../Analytics/StatCard";
import SingleStat from "../Analytics/SingleStat";
import sizeof from "firestore-size";


const SalesStats = ({onUpdate}) => {
    const [statsView, setStatsView] = useState();
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const q = query(collection(db, 'stats-view'), orderBy('createdAt', 'desc'), limit(1));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const docs = querySnapshot.docs
            if (querySnapshot.empty || docs[0].data().createdAt < Date.now() - 24 * 60 * 60 * 1000) {
                // View doesn't exist or is more than 24 hours old
                console.log("Requesting Update")
                const requestUpdate = httpsCallable(functions, 'analytics-createAnalyticsView');
                requestUpdate({view: 'stats-view'});
            } else {
                const data = querySnapshot.docs[0].data();
                console.log(`stats-view size: ${sizeof(data)}B (${(sizeof(data)/(1024*1024)*100).toFixed(1)}%)`);
                onUpdate(data);
                setStatsView(data);
            }
        })
        return unsubscribe;
    }, []);

    const allTimeBySize = () => {
        const items = statsView.allTimeBySize;
        const colCount = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;
        const rows = Math.ceil(items.length / colCount);
        let gridList = [];
        for (let i = 0; i < colCount; i++) {
            gridList.push(
              <Grid xs={12} sm={6} md={4} key={i}>
                {items.slice(i * rows, (i + 1) * rows).map((item, index) => (
                    <SingleStat key={index} inline currentTitle={`EU ${item.size}`} current={item.qty} currentSuffix={`${((item.qty/statsView.allTime*100) || 0).toFixed(1)}%`} />
                ))}
              </Grid>
            );
        }
        return gridList;
    }

    return (
        <Grid container spacing={2} alignContent="stretch">
            <Grid xs={6} md={4}>
                <StatCard title="Total Sold" subtitle="All-Time" loading={!statsView} sx={{height:"100%"}}>
                    {statsView && <SingleStat current={statsView.allTime} />}
                </StatCard>
            </Grid>
            <Grid xs={6} md={4}>
                <StatCard title="Total Sold" subtitle="Last Year" loading={!statsView}>
                    {statsView && <SingleStat current={statsView.lastYear.total} pastTitle="Prev. year" past={statsView.lastYear.compareAt} />}
                </StatCard>
            </Grid>
            <Grid xs={12}>
                <StatCard title="Total Sold By Size" subtitle="All-Time" loading={!statsView}>
                    <Grid container columnSpacing={2}>
                        {statsView && allTimeBySize()}
                    </Grid>
                </StatCard>
            </Grid>
            {statsView && Object.keys(statsView.lastYearByRegion).map(key => {
                const item = statsView.lastYearByRegion[key];
                return (
                    <Grid key={key} xs={6} sm={4}>
                        <StatCard title={key} subtitle="Last Year">
                            <SingleStat current={item.total} past={item.compareAt} pastTitle="Prev. year" />
                        </StatCard>
                    </Grid>
                )
            })}
        </Grid>
    );
}

export default SalesStats;